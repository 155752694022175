import styled from "styled-components"
import colors from "shared/utils/colors"
import { sm } from "mill/utils/breakpoints"

export const Header = styled.h1`
  line-height: 1.2;

  span {
    color: ${props => props.theme.colors.primary};
  }
`

export const Description = styled.div`
  margin-bottom: 0.6rem;

  p {
    color: #707070;
    font-size: 1.7rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const QuickStat = styled.div`
  margin: 2rem 0;
  display: flex;
  align-content: center;

  p {
    display: inline-block;
    margin: 0;
    text-transform: capitalize;
  }

  strong {
    display: block;
    color: ${props => props.theme.colors.primary};
    text-transform: uppercase;
  }
`

export const StatIcon = styled.div`
  width: 4rem;
  height: 4rem;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1rem;
  font-size: 1.5rem;

  i {
    color: ${colors.grayIron};
    font-size: 2.5rem;
    padding: 0.7rem;
  }
`

export const EnrolmentInfo = styled.div`
  color: ${colors.grayDim};

  li {
    padding: 1rem 0;
  }

  button {
    width: 100%;

    @media ${sm} {
      width: auto;
    }
  }
`
